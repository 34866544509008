export default class SeaTurtleModel {
  [key: string]: any;
  seaTurtleId!: string;
  organizationId!: string;
  seaTurtleName?: string;
  sidNumber?: string;
  strandingIdNumber?: string;
  species!: string;
  dateCaptured?: string;
  dateAcquired?: string;
  acquiredFrom?: string;
  turtleSize!: string;
  status!: string;
  dateRelinquished?: string;
  relinquishedTo?: string;
  anomalies?: string;
  injuryBoatStrike!: boolean;
  injuryIntestinalImpaction!: boolean;
  injuryLineEntanglement!: boolean;
  injuryFishHook!: boolean;
  injuryUpperRespiratory!: boolean;
  injuryAnimalBite!: boolean;
  injuryFibropapilloma!: boolean;
  injuryMiscEpidemic!: boolean;
  injuryDoa!: boolean;
  injuryOther!: boolean;
  wasCarryingTagsWhenEnc!: boolean;
  recaptureType!: string;
  tagReturnAddress?: string;
  captureProjectType!: string;
  didTurtleNest!: string;
  captureProjectOther?: string;
  acquiredCounty!: string;
  acquiredLatitude?: number;
  acquiredLongitude?: number;
  relinquishedCounty!: string;
  relinquishedLatitude?: number;
  relinquishedLongitude?: number;
  inspectedForTagScars!: boolean;
  tagScarsLocated?: string;
  scannedForPitTags!: boolean;
  pitTagsScanFrequency?: string;
  scannedForMagneticWires!: boolean;
  magneticWiresLocated?: string;
  inspectedForLivingTags!: boolean;
  livingTagsLocated?: string;
  brochureComments?: string;
  brochureBackgroundColor?: string;
  brochureImageFileAttachmentId?: string;

  constructor() {
    // booleans:  need to be initialized to true or false for controlled checkboxes
    // strings for list items:  need to be initialized to empty string to clear listbox (input select)
    this.species = '';
    this.turtleSize = '';
    this.status = '';
    this.recaptureType = '';
    this.captureProjectType = '';
    this.didTurtleNest = '';
    this.acquiredCounty = '';
    this.relinquishedCounty = '';
    this.injuryBoatStrike = false;
    this.injuryIntestinalImpaction = false;
    this.injuryLineEntanglement = false;
    this.injuryFishHook = false;
    this.injuryUpperRespiratory = false;
    this.injuryAnimalBite = false;
    this.injuryFibropapilloma = false;
    this.injuryMiscEpidemic = false;
    this.injuryDoa = false;
    this.injuryOther = false;
    this.wasCarryingTagsWhenEnc = false;
    this.inspectedForTagScars = false;
    this.scannedForPitTags = false;
    this.scannedForMagneticWires = false;
    this.inspectedForLivingTags = false;
  }
};
